import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { InvoicingService } from 'src/app/admin-portal/modules/invoicing/services/invoicing.service';
import { whitespaceValidator } from 'src/app/utils/whitespace-validation.utils';

@Component({
  selector: 'app-invoicing-expense-dialog',
  templateUrl: './invoicing-expense-dialog.component.html',
})
export class InvoicingExpenseDialogComponent {
  expenseForm!: FormGroup;
  isEditMode: boolean = false;
  isSubmitting: boolean = false;
  isButtonDisabled: boolean = true;
  invoiceID: string = '';
  currency: string = '';

  constructor(
    public matDialog: MatDialogRef<InvoicingExpenseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private invoiceExpenseService: InvoicingService,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.isEditMode = this.data.isEditMode;
    this.invoiceID = this.globalService.getInvoiceId();

    this.initializeForm();
    if (this.isEditMode && this.data.item) {
      this.patchFormValues(this.data.item);
    }

    this.currency = this.data.currency;
  }

  patchFormValues(item: any) {
    this.expenseForm.patchValue({
      invoiceItemID: item.invoiceItemID,
      description: item.description,
      allocatedTime: item.allocatedTime,
      rate: item.rate,
    });
  }

  initializeForm(): void {
    this.expenseForm = this.formBuilder.group({
      description: ['', [Validators.required, whitespaceValidator()]],
      allocatedTime: ['', [Validators.required, this.nonZeroValidator()]],
      rate: ['', Validators.required],
      invoiceItemID: [''],
      invoiceID: [this.invoiceID],
    });

    this.expenseForm.valueChanges.subscribe(() => {
      this.isButtonDisabled =
        this.expenseForm.invalid || !this.expenseForm.dirty;
    });
  }

  onSave(): void {
    if (this.expenseForm.valid && !this.isSubmitting) {
      this.isSubmitting = true;

      const { description, allocatedTime, rate, amount, invoiceItemID } =
        this.expenseForm.value;
      const invoiceID = this.expenseForm.get('invoiceID')?.value;

      // If in edit mode, update the expense
      if (this.isEditMode) {
        const updatedExpenseData = {
          description,
          allocatedTime,
          rate,
          amount,
          invoiceItemID,
        };

        this.invoiceExpenseService
          .updateInvoiceExpense(updatedExpenseData)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: () => {
              this.isSubmitting = false;
              this.matDialog.close({ success: true });
            },
            error: (error: any) => {
              console.error('Error updating expense:', error);
              this.isSubmitting = false;
            },
          });
      } else {
        // If not in edit mode, create an expense
        const newExpenseData = {
          description,
          allocatedTime,
          rate,
          amount,
          invoiceID,
        };

        this.invoiceExpenseService
          .createInvoiceExpense(newExpenseData)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: () => {
              this.isSubmitting = false;
              this.matDialog.close({ success: true });
            },
            error: (error: any) => {
              console.error('Error creating expense:', error);
              this.isSubmitting = false;
            },
          });
      }
    } else {
      console.error('Invalid form data.');
    }
  }

  numericInputValidity: { [key: string]: boolean } = {
    allocatedTime: true,
    rate: true,
  };

  handleNumericInputValidityChange(field: string, validity: boolean) {
    this.numericInputValidity[field] = validity;
  }

  areAllNumericInputsValid(): boolean {
    return Object.values(this.numericInputValidity).every((valid) => valid);
  }

  onNoClick(): void {
    this.matDialog.close();
  }

  nonZeroValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value !== null && value <= 0) {
        return { nonZero: true };
      }
      return null;
    };
  }
}
