import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Validators } from 'ngx-editor';
import { takeUntil } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { InvoicingService } from 'src/app/admin-portal/modules/invoicing/services/invoicing.service';

@Component({
  selector: 'app-payment-received-dialog',
  templateUrl: './payment-received-dialog.component.html',
})
export class PaymentReceivedDialogComponent implements OnInit {
  paymentForm!: FormGroup;
  isButtonDisabled: boolean = true;
  isNumericInputValid: boolean = true;
  currency: string = '';
  backendError: string = '';

  constructor(
    public dialogRef: MatDialogRef<PaymentReceivedDialogComponent>,
    private fb: FormBuilder,
    private invoiceService: InvoicingService,
    private globalService: GlobalService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.currency =
      this.data?.invoiceItemData?.account?.financials[0]?.currency;
  }

  initializeForm() {
    this.paymentForm = this.fb.group({
      paymentDate: ['', Validators.required],
      amount: ['', [Validators.required, Validators.minLength(0)]],
      remark: [''],
    });
    this.paymentForm.valueChanges.subscribe(() => {
      this.isButtonDisabled =
        this.paymentForm.invalid || !this.paymentForm.dirty;
    });
  }

  onSubmit(): void {
    const statusData = this.paymentForm.value;

    const { amount, paymentDate, remark } = statusData;
    const invoiceID = this.data.invoiceID;
    const invoiceStatus = 'received';
    let statusPayload: any = {
      invoiceID,
      amount,
      paymentDate,
      remark,
      invoiceStatus,
    };

    if (this.paymentForm.valid) {
      const updatedStatusData = { ...statusPayload, invoiceID };

      this.invoiceService
        .updatePaymentStatus(updatedStatusData)
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe({
          next: () => {
            this.dialogRef.close({ success: true });
          },
          error: (error: any) => {
            console.error('Error updating status:', error);
            this.backendError =
              error?.error?.message || 'An unknown error occurred';
          },
        });
    } else {
      // Mark all fields as touched to show validation errors
      this.paymentForm.markAllAsTouched();
    }
  }

  handleNumericInputValidityChange(validity: boolean) {
    this.isNumericInputValid = validity;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getCurrencySymbol(currencyCode: string): string {
    switch (currencyCode) {
      case 'INR':
        return '₹';
      case 'USD':
        return '$';
      case 'AUD':
        return '$';
      default:
        return '';
    }
  }
}
