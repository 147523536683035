<div class="modal-wrapper expense-wrapper p-6" [formGroup]="expenseForm">
  <div class="modal-header d-flex justify-between">
    <div>
      <h2 class="mat-subtitle-1 mb-0 text">
        {{ isEditMode ? "Edit Expense" : "Add Expense" }}
      </h2>
      {{
        isEditMode
          ? "Enter details for edit Expense"
          : "Enter details for add Expense"
      }}
    </div>
    <mat-icon class="pointer" (click)="onNoClick()">close</mat-icon>
  </div>
  <input formControlName="invoiceID" type="hidden" />
  <div class="content-wrapper my-4 p-2">
    <div class="input-wrapper text-area-wrapper mb-4">
      <p class="mat-body-1 text mb-1 font-weight-500 input-label">
        Item & Description
      </p>
      <mat-form-field
        class="example-full-width"
        appearance="outline"
        subscriptSizing="dynamic"
      >
        <textarea
          matInput
          placeholder="Ex. It makes me feel..."
          formControlName="description"
        ></textarea>
      </mat-form-field>
      <div
        class="required-star mt-1"
        *ngIf="
          expenseForm.get('description')?.hasError('whitespace') &&
          expenseForm.get('description')?.touched
        "
      >
        Item & Description cannot be empty or contain only whitespace.
      </div>
    </div>
    <div class="row">
      <div class="col s12 m6 l6">
        <sft-input
          [label]="'Quantity'"
          [readonly]="false"
          [placeholder]="'Enter here'"
          [required]="true"
          [infoIcon]="false"
          class="mb-2"
          formControlName="allocatedTime"
          appNumericInput
          (numericInputValidityChange)="
            handleNumericInputValidityChange('allocatedTime', $event)
          "
        />
        <span
          class="required-star"
          *ngIf="!numericInputValidity['allocatedTime']"
          >Please enter a numeric value.</span
        >
        <span
          class="required-star"
          *ngIf="
            expenseForm.get('allocatedTime')?.invalid &&
            (expenseForm.get('allocatedTime')?.touched ||
              expenseForm.get('allocatedTime')?.dirty) &&
            numericInputValidity['allocatedTime']
          "
        >
          Field is required.
        </span>
      </div>
      <div class="col s12 m6 l6">
        <sft-input
          [label]="'Rate (' + currency + ')'"
          [readonly]="false"
          [placeholder]="'Enter here'"
          [required]="true"
          [infoIcon]="false"
          class="mb-2"
          formControlName="rate"
          appNumericInput
          (numericInputValidityChange)="
            handleNumericInputValidityChange('rate', $event)
          "
        />
        <span class="required-star" *ngIf="!numericInputValidity['rate']"
          >Please enter a numeric value.</span
        >
        <span
          class="required-star"
          *ngIf="
            expenseForm.get('rate')?.invalid &&
            (expenseForm.get('rate')?.touched ||
              expenseForm.get('rate')?.dirty) &&
            numericInputValidity['rate']
          "
        >
          Field is required.
        </span>
      </div>
    </div>
  </div>
  <div class="modal-footer mt-2 d-flex justify-end">
    <sft-button
      [style.cursor]="isButtonDisabled ? 'not-allowed' : 'pointer'"
      [label]="isEditMode ? 'Update' : 'Save'"
      [color]="'primary'"
      [variant]="'flat'"
      [size]="'large'"
      [disable]="isButtonDisabled"
      (click)="isButtonDisabled ? null : onSave()"
    ></sft-button>
  </div>
</div>
