<div class="modal-wrapper expense-wrapper p-6">
  <div class="modal-header d-flex justify-between">
    <div>
      <h2 class="mat-subtitle-1 mb-0 text">Payment Received</h2>
      <p class="textXS mt-2 text">Enter amount and date of receiving.</p>
    </div>
    <mat-icon class="pointer" (click)="onNoClick()">close</mat-icon>
  </div>
  <form [formGroup]="paymentForm" (ngSubmit)="onSubmit()">
    <div class="my-4 p-2">
      <div class="input-wrapper mb-6">
        <p class="mat-body-1 text mb-1 input-label">
          Date<span class="required-star">*</span>
        </p>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <input
            matInput
            [matDatepicker]="startpicker"
            required
            placeholder="dd/mm/yyyy"
            formControlName="paymentDate"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="startpicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startpicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col s12 m6 l6 mb-4">
        <p class="mat-body-1 text mb-1 input-label">
          Amount ({{ getCurrencySymbol(currency) }})
          <span class="required-star">*</span>
        </p>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <input
            matInput
            required
            placeholder="Enter here"
            formControlName="amount"
            appNumericInput
            (numericInputValidityChange)="
              handleNumericInputValidityChange($event)
            "
          />
        </mat-form-field>
      </div>

      <div class="input-wrapper text-area-wrapper mb-4">
        <p class="mat-body-1 text mb-1 font-weight-500 input-label">Remarks</p>
        <mat-form-field
          class="example-full-width"
          appearance="outline"
          subscriptSizing="dynamic"
        >
          <textarea
            matInput
            placeholder="Enter here"
            rows="5"
            formControlName="remark"
          ></textarea>
        </mat-form-field>
      </div>
      <div *ngIf="backendError" class="required-star">
        {{ backendError }}
      </div>
    </div>
    <div class="modal-footer mt-2 d-flex justify-end">
      <sft-button
        [style.cursor]="isButtonDisabled ? 'not-allowed' : 'pointer'"
        [label]="'Save'"
        [color]="'primary'"
        [variant]="'flat'"
        [disableRipple]="false"
        [size]="'large'"
        type="submit"
        [disable]="isButtonDisabled"
      ></sft-button>
    </div>
  </form>
</div>
